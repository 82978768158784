import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { IMenuTypes } from 'utils/models';
import storage from 'utils/storage';
import useAuth from 'hooks/useAuth';

// components
import SideActions from './SideActions';
import {
  SvgLefRightArrow,
  SvgSideConsultations,
  SvgSideCoupon,
  SvgSideDoctor,
  SvgSidePatient,
  SvgSidePrescription,
  SvgSideProducts
} from './SvgIcons';
import { LogoutIcon } from '@heroicons/react/outline';
import { UserIcon } from '@heroicons/react/solid';
import Button from './Button';

const SideBar: FC = () => {
  const { logout, user } = useAuth();
  const [isCollapse, setIsCollapse] = useState<boolean>(false);

  const handleCollapse = () => {
    const collapse = !isCollapse;
    setIsCollapse(collapse);
    storage.setItem('collapse', collapse ? 'true' : 'false');
  };

  const links: IMenuTypes[] = [];

  if (
    user?.is_super_admin ||
    user?.user_type?.patient_access ||
    user?.user_type?.login_patient_access
  ) {
    links.push({
      icon: <SvgSidePatient className="w-6 h-6" />,
      label: 'Patients',
      to: '/patients'
    });
  }

  if (
    user?.is_super_admin ||
    user?.user_type?.doctor_access ||
    user?.user_type?.login_doctor_access
  ) {
    links.push({
      icon: <SvgSideDoctor className="w-6 h-6" />,
      label: 'Doctors',
      to: '/doctors'
    });
  }

  if (
    user?.is_super_admin ||
    user?.user_type?.consultation_access ||
    user?.user_type?.reset_consultation_access ||
    user?.user_type?.generate_prescription_access ||
    user?.user_type?.reschedule_access ||
    user?.user_type?.did_not_attend_access ||
    user?.user_type?.cancel_access ||
    // @TODO
    user?.user_type?.consultation_logs_access
  ) {
    links.push({
      icon: <SvgSideConsultations className="w-6 h-6" />,
      label: 'Consultations',
      to: '/consultations'
    });
  }

  if (user?.is_super_admin || user?.user_type?.product_access) {
    links.push({
      icon: <SvgSideProducts className="w-6 h-6" />,
      label: 'Products',
      to: '/products'
    });
  }

  if (
    user?.is_super_admin ||
    user?.user_type?.prescription_access ||
    user?.user_type?.prescription_nova_access ||
    user?.user_type?.prescription_air_access
  ) {
    links.push({
      icon: <SvgSidePrescription className="w-6 h-6" />,
      label: 'Orders',
      to: '/orders'
    });
  }

  if (user?.is_super_admin || user?.user_type?.coupon_access) {
    links.push({
      icon: <SvgSideCoupon className="w-6 h-6" />,
      label: 'Coupons',
      to: '/coupons'
    });
  }

  if (user?.is_super_admin) {
    links.push({
      icon: <UserIcon className="w-6 h-6" />,
      label: 'User Management',
      actions: [
        {
          label: 'User Access Management',
          to: '/users'
        },
        {
          label: 'User Types Management',
          to: '/user-types'
        },
        {
          label: 'Payment Transactions',
          to: '/payment-transactions'
        }
      ]
    });
  }

  links.push({
    icon: <LogoutIcon className="w-6 h-6" />,
    label: 'Log out',
    setting: true,
    onClick: () => logout()
  });

  useEffect(() => {
    const collapse = storage.getItem('collapse');
    if (collapse) setIsCollapse(JSON.parse(collapse));
  }, []);

  return (
    <div className="hidden md:block bg-polln-light-green-500 h-full">
      <div className={clsx('bg-polln-light-green-500 h-full relative', !isCollapse && 'w-max')}>
        <div className="absolute top-2.5 -right-3.5">
          <Button onClick={() => handleCollapse()} size="inline" variant="invisible">
            <div className="bg-polln-solid-green-600 rounded-full p-1">
              <SvgLefRightArrow className="text-white w-4 h-4" />
            </div>
          </Button>
        </div>

        <div className="pt-5">
          <SideActions actions={links} isCollapse={isCollapse} />
        </div>
      </div>
    </div>
  );
};

export default SideBar;

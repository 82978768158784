import React, { FC } from 'react';
import clsx, { ClassValue } from 'clsx';
import { IMenuTypes } from 'utils/models';
import { Link } from 'react-router-dom';

// components
import MenuAction from 'components/MenuAction';
import { SvgPollnBrand, SvgSideSettings } from 'components/SvgIcons';
import { IS_DEV } from 'utils/constants';
import { CONF_ENVIRONMENT } from 'utils/config';

interface IHeader {
  className?: ClassValue;
  menu: IMenuTypes[];
}

const Header: FC<IHeader> = ({ className, menu }) => {
  const isDev = IS_DEV.includes(CONF_ENVIRONMENT || 'development');

  return (
    <div className={clsx(className, 'relative w-full', isDev && 'bg-red-300')}>
      <div className="hidden md:block p-3 mx-auto">
        {/* <div className={clsx(isDev && 'flex justify-between items-center')}> */}
        <div className={clsx('flex justify-between items-center')}>
          <div className="flex items-left space-x-10">
            <Link to="/">
              <div className="flex justify-start flex-col items-left space-y-0.5 text-left">
                <SvgPollnBrand className="text-polln-solid-green-600 w-16 h-5" />
                <div className="text-gray-500 font-bold text-sm">Admin Dashboard</div>
              </div>
            </Link>
          </div>

          {isDev && (
            <div className="flex justify-center uppercase text-xl font-bold">
              You are using {CONF_ENVIRONMENT} environment
            </div>
          )}

          <div className="flex justify-end items-center px-5">
            <div className="hidden md:block">
              <MenuAction
                menuButton={<SvgSideSettings className="w-9 h-9 text-black" />}
                classNameMain="ml-5"
                menu={menu}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

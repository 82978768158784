import React, { FC } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import {
  IMenuActions,
  IMenuButton,
  IMenuLink,
  IMenuAtag,
  IMenuTypes,
  isIMenuAtag,
  isIMenuButton,
  isIMenuLink,
  isMenuActions
} from 'utils/models';

// components
import Button from './Button';
import MenuAction from './MenuAction';

interface ISideActions {
  actions: IMenuTypes[];
  isCollapse?: boolean;
  onMouseEnter?: (e: MouseEvent) => void;
  onMouseLeave?: (e: MouseEvent) => void;
}

const SideActions: FC<ISideActions> = ({ actions, isCollapse = false }) => {
  return (
    <>
      {/**
       * for side nav links
       */}
      {actions
        .filter((item: IMenuTypes) => !item.setting)
        .map((item: IMenuTypes, index: number) => (
          <div key={index}>
            {isIMenuLink(item) && <NavLink item={item} isCollapse={isCollapse} />}

            {isIMenuAtag(item) && <HrefNav item={item} isCollapse={isCollapse} />}

            {isIMenuButton(item) && <NavButton item={item} isCollapse={isCollapse} />}

            {isMenuActions(item) && <MenuActionItems item={item} isCollapse={isCollapse} />}

            {item.divider && <div className="my-5 border border-gray-100" />}
          </div>
        ))}

      <div className="absolute bottom-0 w-full">
        {actions
          .filter((item: IMenuTypes) => item.setting)
          .map((item: IMenuTypes, index: number) => (
            <div key={index}>
              {isIMenuLink(item) && <NavLink item={item} isCollapse={isCollapse} />}

              {isIMenuAtag(item) && <HrefNav item={item} isCollapse={isCollapse} />}

              {isIMenuButton(item) && <NavButton item={item} isCollapse={isCollapse} />}

              {isMenuActions(item) && <MenuActionItems item={item} isCollapse={isCollapse} />}

              {item.divider && <div className="my-5 border border-gray-100" />}
            </div>
          ))}
      </div>
    </>
  );
};

// MENU ITEMS this is the "settings"
interface IMenuActionItems {
  item: IMenuActions;
  isCollapse?: boolean;
}

const MenuActionItems: FC<IMenuActionItems> = ({ item, isCollapse = false }) => {
  return (
    <MenuAction
      placement="auto"
      classNameMain="w-full"
      menuButton={
        <Button
          variant="invisible"
          size="inline"
          rounded={item?.itemRound || 'none'}
          className={clsx(
            'w-full',
            'flex items-center border-transparent text-white font-bold',
            'hover:bg-white'
          )}
          classNameButton={clsx(!isCollapse && 'space-x-6', 'py-5 px-8')}
        >
          {item.icon && <div className={clsx('text-polln-solid-green-500')}>{item.icon}</div>}
          {!isCollapse && (
            <span className={clsx('text-sm text-polln-solid-green-500 font-bold')}>
              {item.label}
            </span>
          )}
        </Button>
      }
      menu={item.actions}
    />
  );
};

// NAV LINK FOR LINK ROUTES
interface INavLink {
  item: IMenuLink;
  isCollapse?: boolean;
}

const NavLink: FC<INavLink> = ({ item, isCollapse = false }) => {
  const location = useLocation();
  const active = location.pathname.indexOf(item.to as string) === 0 || false;

  return (
    <Link
      to={item.to}
      className={clsx(
        active && 'bg-polln-solid-green-500 dashicon pointer-events-none',
        !isCollapse && 'space-x-6',
        'py-5 px-8 flex items-center border-transparent text-polln-black-400',
        'hover:bg-white'
      )}
    >
      {item.icon && (
        <div className={clsx('text-polln-solid-green-500', active && '!text-white')}>
          {item.icon}
        </div>
      )}
      {!isCollapse && (
        <span
          className={clsx(
            'text-sm',
            active ? 'text-white font-bold' : 'text-polln-solid-green-500 font-bold'
          )}
        >
          {item.label}
        </span>
      )}
    </Link>
  );
};

// HREF FOR ANCHOR TAGS <a href...>
interface IHrefNav {
  item: IMenuAtag;
  isCollapse?: boolean;
}

const HrefNav: FC<IHrefNav> = ({ item, isCollapse = false }) => {
  return (
    <a
      href={item.href}
      className={clsx(
        'w-full',
        'flex items-center border-transparent text-polln-black-400',
        'hover:bg-white hover:shadow-md',
        !isCollapse && 'space-x-6',
        'py-5 px-8'
      )}
      target={item.target}
      rel="noreferrer"
    >
      {item.icon && <div className={clsx('text-polln-solid-green-500')}>{item.icon}</div>}
      {!isCollapse && (
        <span className={clsx('text-xs text-polln-solid-green-500 font-bold')}>{item.label}</span>
      )}
    </a>
  );
};

// NAV BUTTON FOR BUTTONS
interface INavButton {
  item: IMenuButton;
  isCollapse?: boolean;
}

const NavButton: FC<INavButton> = ({ item, isCollapse = false }) => {
  return (
    <Button
      variant="invisible"
      type={item.type}
      onClick={item.onClick}
      size="inline"
      rounded={item?.itemRound || 'none'}
      className={clsx(
        'w-full',
        'flex items-center border-transparent text-polln-black-400',
        'hover:bg-white'
      )}
      classNameButton={clsx(!isCollapse && 'space-x-6', 'py-5 px-8 ')}
    >
      {item.icon && <div className={clsx('text-polln-solid-green-500')}>{item.icon}</div>}
      {!isCollapse && (
        <span className={clsx('text-sm text-polln-solid-green-500 font-bold')}>{item.label}</span>
      )}
    </Button>
  );
};

export default SideActions;
